<template>
  <div class="discover container" v-if="$apollo.data.campaigns">
    <div>
      <base-filters
        :filters="campaignFilters"
        :hideCategoryFilter=true
        :hideLocationFilter=true
        @locationvalue="locationValue"
        @categoryvalue="categoryValue"
        @searchvalue="searchvalue"
      />
      <div v-if="$apollo.loading && this.isFirst">
        <base-skeleton-loader
          type="discover"
          :count="8"
          :isApply="true"
        ></base-skeleton-loader>
      </div>
      <div v-else>
        <compaign-card
          v-for="campaign in campaigns"
          :key="campaign.id"
          :campaign="campaign"
          :isApply="true"
        />
        <infinite-loading
          @infinite="infiniteHandler"
          spinner="spiral"
          :identifier="infiniteId"
          :distance="30"
          v-if="!isFirst"
        >
        </infinite-loading>
      </div>
    </div>
    <div v-if="campaigns.length == 0" class="no-record">{{$t('No Record Found')}}</div>
  </div>
</template>

<script>
import { CAMPAIGNS } from "@/graphql/user/query";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      campaigns: [],
      filterLocations: null,
      filterCategories: null,
      search: "",
      page: 1,
      selected: "",
      norecord: false,
      infiniteId: +new Date(),
      hasMore: true,
      isFirst: true,
      limit: 12,
    };
  },
  components: {
    CompaignCard: () =>
      import(
        /* webpackChunkName: "capaignCard" */ "@/components/user/CompaignCard.vue"
      ),
  },
  computed: {
    ...mapGetters("filters", ["campaignFilters"]),
  },
  campaignFilters: {
    handler() {
      this.filterLocations =
        this.campaignFilters.default != null
          ? this.$store.campaignFilters.default.locations.map((item) => item.id)
          : null;
      this.filterCategories =
        this.campaignFilters.default != null
          ? this.$store.campaignFilters.default.categories.map(
              (item) => item.id
            )
          : null;
    },
  },
  apollo: {
    campaigns: {
      query: CAMPAIGNS,
      variables() {
        return {
          page: 1,
          locations: this.filterLocations,
          categories: this.filterCategories,
          search: this.search,
          subscriptions: null,
          limit: this.limit,
        };
      },
    },
  },
  watch: {
    campaigns() {
      this.isFirst = false;
    },
  },
  methods: {
    locationValue(data) {
      this.filterLocations = data;
      let newFilter = this.filterLocations.map((item) => item.id);
      if (this.filterLocations.length == 0) {
        this.filterLocations = null;
      } else {
        this.filterLocations = newFilter;
      }
      this.hasMore = true;
      this.infiniteId += 1;
    },
    categoryValue(data) {
      this.filterCategories = data;
      let newFilter = this.filterCategories.map((item) => item.id);
      if (this.filterCategories.length == 0) {
        this.filterCategories = null;
      } else {
        this.filterCategories = newFilter;
      }
      this.hasMore = true;
      this.infiniteId += 1;
    },
    searchvalue(data) {
      this.search = data;
      this.hasMore = true;
      this.infiniteId += 1;
    },
    infiniteHandler($state) {
      if (this.hasMore) {
        setTimeout(() => {
          try {
            this.page++;
            this.$apollo.queries.campaigns.fetchMore({
              variables: {
                page: this.page,
                lcoations: this.filterLocations,
                categories: this.filterCategories,
                search: this.search,
                subscriptions: null,
                limit: this.limit,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newCampaigns = fetchMoreResult.campaigns;
                if (newCampaigns.length) {
                  $state.loaded();
                } else {
                  $state.complete();
                  this.hasMore = false;
                }
                if (newCampaigns.length < this.limit) {
                  this.hasMore = false;
                }
                return {
                  campaigns: [...previousResult.campaigns, ...newCampaigns],
                };
              },
            });
          } catch (err) {
            console.log("fetchMore error", JSON.stringify(err));
          }
        }, 500);
      } else {
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
